import {takeEvery, takeLatest} from 'redux-saga/effects';
import {dbg} from '@common/debug/debug';
import {isEqual as lodashIsEqual} from 'lodash';
import {
  convertDateToUTC,
  DateTimeToString,
  getTimestamp,
} from '@common/utils/utils';
import {
  ActionNames,
  defaultGlobalState,
  Dispatch,
  FirebaseLoggedIn,
  GlobalState,
  InfoNames,
  ReduxActions,
} from '@common/redux/redux';
import {Store} from '@abstractions/redux_inst';
import {
  TuningElement,
  InstrumentElement,
} from '@common/instruments/instrument_defs';
import {NoteMappings} from '@common/instruments/note_mapping';
import {DataSnapshot, FbPlatform, FirebaseClass} from '@abstractions/firebase';
export const FbTingsToStore = {
  UserEditedInstruments: 'UserInstruments',
};

export type FirebaseUserPrivate = {
  uid: string;
  timestampSeconds: string;
  timestamp: string;
};

// Stored in Firebase under /device_settings/uid/OS/
// where OS is 'web' or 'android' or 'ios'
export type FirebaseDeviceSettings = {
  ReferenceFrequency?: number;
  Transposition?: number;
  Temperament?: string;
  Instrument?: string;
  SubInstrument?: string;
  Tuning?: string;
  FftSize?: number;
};

// Stored in Firebase under /users_settings/uid/
export type FirebaseUserSettings = {
  UserInstruments?: TuningElement[];
};

// --------------------------------------------------------------------------
let firebaseAsyncInst: FirebaseAsyncStorage | null = null;
export class FirebaseAsyncStorage {
  mInstrumentDirty = false;
  mDirty = false;
  mScheduled: boolean = false;

  static inst(): FirebaseAsyncStorage {
    if (firebaseAsyncInst === null) {
      firebaseAsyncInst = new FirebaseAsyncStorage();

      FirebaseClass.inst().onAuthStateChanged((user: any) => {
        dbg.log('FirebaseAsyncStorage::onAuthStateChanged()');
        const isSignedIn = !!user && !!user.uid && user?.uid?.length > 0;
        const uid = isSignedIn ? user.uid : '';
        const store = FirebaseAsyncStorage.inst().getStore();
        const dS = defaultGlobalState;

        // We will also listen to the event that we are triggering here.
        store.dispatch(Dispatch.setFirebaseLoggedIn(isSignedIn, uid, user));

        if (!isSignedIn) {
          store.dispatch(Dispatch.resetInstruments());
          store.dispatch(
            Dispatch.setAdvancedGraphicsAction(dS.advancedGraphicsOn),
          );
          store.dispatch(
            Dispatch.setCurrentTemperamentByName(dS.currentTemperamentName),
          );
          store.dispatch(Dispatch.setFftSize(dS.fftSize));
          store.dispatch(Dispatch.setRefFreq(dS.referenceFreq));
          store.dispatch(Dispatch.setTransposition(dS.transposition));
          store.dispatch(Dispatch.setIsProVersion(dS.isProVersion));
          store.dispatch(
            Dispatch.setAdvancedGraphicsAction(dS.advancedGraphicsOn),
          );
        }
      });
    }
    return firebaseAsyncInst;
  }

  getState(): GlobalState {
    return Store.inst().store.getState().system;
  }

  getStore() {
    return Store.inst().store;
  }

  isLoggedIn(): boolean {
    return this.getState().firebaseUid.length > 0;
  }

  isLoadCompleted(): boolean {
    return this.isLoggedIn() && this.getState().firebaseLoadCompleted;
  }

  // --------------------------------------------------------------------------
  scheduleSyncToFirebase() {
    if (this.mScheduled) {
      return;
    }
    this.mScheduled = true;
    if (this.isLoadCompleted() && this.isLoggedIn()) {
      setTimeout(() => {
        this.mScheduled = false;
        if (this.mDirty) {
          const ds: FirebaseDeviceSettings = getCurrentDeviceSettings();
          const db = FirebaseClass.inst().db();
          db.ref()
            .child(
              '/device_settings/' +
                this.getState().firebaseUid +
                '/' +
                FbPlatform.OS +
                '/',
            )
            .set(ds)
            .then(() => {
              dbg.log('FirebaseAsyncStorage::Set device settings OK.');
              this.mDirty = false;
            })
            .catch((reason: any) => {
              console.warn(
                'FirebaseAsyncStorage::Error when setting user ' +
                  this.getState().firebaseUid +
                  ' settings. ',
                reason,
              );
            });
        }

        if (this.mInstrumentDirty) {
          const state: GlobalState = this.getState();

          // We only handle instrument updates if firebase has been loaded.
          if (state.firebaseLoadCompleted && FbPlatform.OS === 'web') {
            // Gets all modified tunings and saves them to Firebase
            const jsonArr = NoteMappings.inst().serializeModifiedTunings();
            if (jsonArr.length > 0) {
              try {
                dbg.logObj('Saving instruments:', jsonArr);

                const mMyInstruments = [...jsonArr];

                const db = FirebaseClass.inst().db();
                dbg.log('FirebaseAsyncStorage::sendInstrumentsToFirebase()');
                dbg.logObj(
                  'FirebaseAsyncStorage::sendInstrumentsToFirebase()',
                  mMyInstruments,
                );
                db.ref()
                  .child(
                    '/users_settings/' + state.firebaseUid + '/UserInstruments',
                  )
                  .set(mMyInstruments)
                  .then(() => {
                    dbg.log('FirebaseAsyncStorage::Set instruments OK.');
                  })
                  .catch((reason: any) => {
                    console.warn(
                      'FirebaseAsyncStorage::Error when setting user ' +
                        state.firebaseUid +
                        ' settings. ',
                      reason,
                    );
                  });
              } catch (e: any) {
                console.warn(e.message, e);
              }
            }
          } else {
            dbg.log(
              'firebase::onInstrumentsUpdated():Firebase not loaded yet, so skipping',
            );
          }
        }
      }, 2000);
    }
  }

  deleteAllUserData(): Promise<void> {
    if (FbPlatform.OS !== 'web' || this.getState().firebaseUid.length === 0) {
      return Promise.resolve();
    }
    return FirebaseClass.inst().deleteAllUserData(this.getState().firebaseUid);
  }
}

function FirebaseMiddleWare(_store: any) {
  return (next: any) => {
    return (actionAny: any /*ReduxActions*/) => {
      const action: ReduxActions = actionAny;
      switch (action.type) {
        // case 'AddInstrumentAction': {
        // Intercept the instrument being added and make it searchable from child to parent.
        //  const addInstrument: AddInstrumentAction = actionAny;
        //  const instrumentAny: any = addInstrument.instrument;
        //  recursiveAddParents(instrumentAny);
        //  break;
        // }
        default:
          break;
      }

      // continue processing this action
      return next(action);
    };
  };
}

// Only called after successful login
function _saveLoginTimestampToFb(uid: string) {
  const priv: FirebaseUserPrivate = {
    uid,
    timestampSeconds: getTimestamp(),
    timestamp: DateTimeToString(convertDateToUTC(new Date())),
  };

  const db = FirebaseClass.inst().db();

  db.ref()
    .child('/users_private/' + uid + '/' + FbPlatform.OS + '/')
    .set(priv)
    .then((_value: any) => {
      dbg.log('Firebase::Wrote data ', priv);
    })
    .catch((reason: any) => {
      console.warn('Firebase::Could not write to database, reason = ', reason);
    });
}

function _extractDeviceSettingsFromFb(
  userSettings: DataSnapshot,
): FirebaseDeviceSettings {
  const devSettings: FirebaseDeviceSettings = {};

  try {
    const settingsIn = userSettings.val();
    if (settingsIn) {
      for (const [attr, value] of Object.entries(settingsIn)) {
        if (value) {
          const v: string = value.toString();
          // Add to the device settings
          switch (attr) {
            case 'ReferenceFrequency':
              devSettings.ReferenceFrequency = parseFloat(v);
              break;
            case 'Transposition':
              devSettings.Transposition = parseInt(v, 10);
              break;
            case 'Temperament':
              devSettings.Temperament = v;
              break;
            case 'Instrument':
              devSettings.Instrument = v;
              break;
            case 'SubInstrument':
              devSettings.SubInstrument = v;
              break;
            case 'Tuning':
              devSettings.Tuning = v;
              break;
            case 'FftSize':
              if (FbPlatform.OS !== 'web') {
                devSettings.FftSize = parseInt(v, 10);
              }
              break;
            default:
              dbg.log(
                'Firebase::_extractDeviceSettings():Unknown attribute:',
                attr,
              );
              break;
          }
        }
      }
    }
  } catch (e: any) {
    console.error(e.message, e);
  }
  return devSettings;
}

// Fetch the device settings
function getCurrentDeviceSettings(): FirebaseDeviceSettings {
  const globalState: GlobalState = FirebaseAsyncStorage.inst()
    .getStore()
    .getState().system;
  const ds: FirebaseDeviceSettings = {
    ReferenceFrequency: globalState.referenceFreq,
    Transposition: globalState.transposition,
    Instrument: globalState.currentInstrumentName,
    SubInstrument: globalState.currentSubInstrumentName,
    Tuning: globalState.currentTuningName,
    FftSize: globalState.fftSize,
  };
  return ds;
}

// Only called after successful login
function _handleLogin(_user: any) {
  async function _handleLoginAsync(user: any) {
    const inst = FirebaseClass.inst();
    const db = inst.db();

    const isSignedIn = !!user;
    if (!isSignedIn) {
      dbg.err('Firebase::_handleLogin():User is not signed in');
      return;
    }

    const uid: string = user?.uid ? user?.uid : '';
    if (uid.length === 0) {
      dbg.err('Firebase::_handleLogin():uid is empty');
      return;
    }

    inst.initDatabase(uid);

    dbg.log('Firebase::isSignedIn = ', isSignedIn, uid);

    _saveLoginTimestampToFb(uid); // Save the login timestamp to Firebase

    try {
      const userSettingsRef = db
        .ref()
        .child(`/users_settings/${uid}/UserInstruments`);
      const instrumentsSnapshot: DataSnapshot = await userSettingsRef.once(
        'value',
      );

      const instrumentsIn: null | [TuningElement] = instrumentsSnapshot.val();
      dbg.logObj('instrumentsIn:', instrumentsIn);

      if (instrumentsIn && instrumentsIn.length > 0 && instrumentsIn[0].name) {
        const serializedTunings = [...instrumentsIn];

        if (serializedTunings.length > 0) {
          const arrayOfNewInstruments =
            NoteMappings.inst().deSerializeModifiedTunings(serializedTunings);
          dbg.log('arrayOfNewInstruments:', arrayOfNewInstruments);
          arrayOfNewInstruments.forEach((instrument: InstrumentElement) => {
            FirebaseAsyncStorage.inst()
              .getStore()
              .dispatch(Dispatch.addInstrument(instrument, undefined, true));
          });
        }
      }
    } catch (e: any) {
      console.error(e.message, e);
    }

    let ds = getCurrentDeviceSettings();
    const devBackup = {...ds};
    for (let i = 0; i < 2; i++) {
      // On first attempt, fetch the legacy user settings if they are present.
      // On second attempt, fetch the device settings (new model).
      const path =
        i === 0
          ? '/users_settings/' + uid + '/'
          : '/device_settings/' + uid + '/' + FbPlatform.OS + '/';
      try {
        const userSettings: DataSnapshot = await db
          .ref()
          .child(path)
          .once('value');

        const settingsIn = userSettings.val();
        if (settingsIn) {
          const newDevSettings = _extractDeviceSettingsFromFb(userSettings);
          ds = {...ds, ...newDevSettings};
        }
      } catch (e: any) {
        dbg.warn(e.message, e);
      }
    }

    if (!lodashIsEqual(ds, devBackup)) {
      // Update reference frequency
      if (
        !!ds.ReferenceFrequency &&
        ds.ReferenceFrequency > 0 &&
        ds.ReferenceFrequency !== devBackup.ReferenceFrequency
      ) {
        dbg.log(
          'Firebase::_handleLogin():Setting reference frequency to:',
          ds.ReferenceFrequency,
        );
        FirebaseAsyncStorage.inst()
          .getStore()
          .dispatch(Dispatch.setRefFreq(ds.ReferenceFrequency));
      }

      // Update transposition
      if (!!ds.Transposition && ds.Transposition !== devBackup.Transposition) {
        dbg.log(
          'Firebase::_handleLogin():Setting transposition to:',
          ds.Transposition,
        );
        FirebaseAsyncStorage.inst()
          .getStore()
          .dispatch(Dispatch.setTransposition(ds.Transposition));
      }

      // Update instrument
      if (
        ds.Instrument !== devBackup.Instrument ||
        ds.SubInstrument !== devBackup.SubInstrument ||
        ds.Tuning !== devBackup.Tuning
      ) {
        if (
          ds.Instrument &&
          ds.Instrument.length > 0 &&
          ds.SubInstrument &&
          ds.SubInstrument.length > 0 &&
          ds.Tuning &&
          ds.Tuning.length > 0
        ) {
          dbg.log(
            'Firebase::_handleLogin():Setting instrument to:',
            ds.Instrument,
            ds.SubInstrument,
            ds.Tuning,
          );
          FirebaseAsyncStorage.inst()
            .getStore()
            .dispatch(
              Dispatch.setCurrentInstrumentByName(
                ds.Instrument,
                ds.SubInstrument,
                ds.Tuning,
              ),
            );
        } else {
          dbg.log('Firebase::_handleLogin():Resetting instrument');
          FirebaseAsyncStorage.inst()
            .getStore()
            .dispatch(Dispatch.resetInstruments());
        }
      }

      // Update fft size
      if (!!ds.FftSize && ds.FftSize !== devBackup.FftSize) {
        dbg.log('Firebase::_handleLogin():Setting fft size to:', ds.FftSize);
        FirebaseAsyncStorage.inst()
          .getStore()
          .dispatch(Dispatch.setFftSize(ds.FftSize));
      }

      // Update temperament. Actually, don't.
    }
    FirebaseAsyncStorage.inst()
      .getStore()
      .dispatch(Dispatch.setFirebaseLoadCompleted());
  }
  _handleLoginAsync(_user).then(() => {
    dbg.log('Firebase::_handleLogin():Completed');
  });
}

// -----------------------------------------------------------------------------
let loadedAlready = false; // TODO: This is a hack. We should not need this.
function _handleAsyncStorageLoadCompleted() {
  dbg.log('firebase.ts::Placeholder::handleAsyncStorageLoadCompleted');
  if (!loadedAlready) {
    loadedAlready = true;

  } else {
    // Todo, who is calling this twice?
    dbg.log(
      'firebase.ts::Placeholder::handleAsyncStorageLoadCompleted skipped',
    );
  }
}

// -----------------------------------------------------------------------------
function* onFirebaseLoginChanged(_actionAny: any, ..._otherArgs: any[]) {
  dbg.log('async::onFirebaseLoginChanged()');

  const handleLoginOrLogout = (actionAny: any) => {
    dbg.log('async::onFirebaseLoginChanged()::handleLoginOrLogout()');
    if (actionAny.type === ActionNames.FirebaseLoggedIn) {
      const a: FirebaseLoggedIn = actionAny as FirebaseLoggedIn;
      if (a.loggedIn) {
        _handleLogin(a.user);
      }
    }
  };

  yield takeEvery(ActionNames.FirebaseLoggedIn, handleLoginOrLogout);
}

// -----------------------------------------------------------------------------
function* handleSettingsDirty(actionAny: any) {
  // Handle the set transposition action
  dbg.log('Handling handleSettingsDirty:');
  // Perform any side effects here
  FirebaseAsyncStorage.inst().mDirty = true;
  if (FirebaseAsyncStorage.inst().isLoadCompleted()) {
    FirebaseAsyncStorage.inst().scheduleSyncToFirebase();
  }

  yield 0;
}

// -----------------------------------------------------------------------------
function* handleInstrumentsDirty(actionAny: any, ..._otherArgs: any[]) {
  // Handle the set transposition action
  dbg.log('Handling handleInstrumentsDirty:', actionAny);
  // Perform any side effects here
  FirebaseAsyncStorage.inst().mInstrumentDirty = true;
  if (FirebaseAsyncStorage.inst().isLoadCompleted()) {
    FirebaseAsyncStorage.inst().scheduleSyncToFirebase();
  }

  yield 0;
}

export function* watchSettingsActions() {
  yield takeEvery(ActionNames.ResetInstrumentsAction, handleSettingsDirty);
  yield takeEvery(ActionNames.SetTranspositionAction, handleSettingsDirty);
  yield takeEvery(ActionNames.SetInstrumentsDirtyAction, handleSettingsDirty);
  yield takeEvery(ActionNames.SetCurrentInstrumentAction, handleSettingsDirty);
  yield takeEvery(ActionNames.SetTuningAction, handleSettingsDirty);
  yield takeEvery(ActionNames.SetReferenceFreqAction, handleSettingsDirty);
  yield takeEvery(ActionNames.SetFftSizeAction, handleSettingsDirty);
  yield takeEvery(InfoNames.QueueSaveInstrumentsToNvm, handleInstrumentsDirty);
  yield takeLatest(
    InfoNames.AsyncStorageLoadCompleted,
    _handleAsyncStorageLoadCompleted,
  );
}

// -----------------------------------------------------------------------------
const FirebaseSideEffects = [onFirebaseLoginChanged, watchSettingsActions];

export {FirebaseMiddleWare, FirebaseSideEffects};
