import {call, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  ActionNames,
  ChooseAudioDeviceByDeviceIdAction,
  CustomerInfoChangedInfo,
  Dispatch,
  InfoNames,
  ReduxActions,
  SetAdvancedGraphicsAction,
  SetCurrentTemperamentAction,
  SetFftSizeAction,
  SetIsProVersionAction,
  SetReferenceFreqAction,
  SetSensitivityDbAction,
  SetShowAmplitudeAction,
  SetStrobeDisplayTypeAction,
  SetTranspositionAction,
  SetTuningAction,
  StartedAudioAction,
} from './redux/redux';

import {Store} from '@abstractions/redux_inst';

// tslint:disable-next-line
import AsyncStorage from '@react-native-async-storage/async-storage';
import {dbg} from './debug/debug';
import {PlatformType} from '@abstractions/platform';

export const TingsToStore = {
  ReferenceFrequency: 'ReferenceFrequency',
  Transposition: 'Transposition',
  Instrument: 'Instrument',
  SubInstrument: 'SubInstrument',
  Tuning: 'Tuning',
  Temperament: 'Temperament',
  AudioInputDevice: 'AudioInputDevice',
  ShowAmplitude: 'ShowAmplitude',
  AdvancedGraphics: 'AdvancedGraphics',
  StrobeDisplayType: 'StrobeDisplayType',
  Sensitivity: 'Sensitivity',
  FftSize: 'FftSize',
  IsProVersion: 'IsProVersion',
  CustomerInfo: 'CustomerInfo',
};

let memInst: Memories | null = null;
let asyncInst: AsyncMemory | null = null;

export enum AsyncChangeSources {
  nvm = 'nvm',
  user = 'user',
}

// -----------------------------------------------------------------------------
export class AsyncMemory {
  static inst(): AsyncMemory {
    if (asyncInst === null) {
      asyncInst = new AsyncMemory();
    }
    return asyncInst;
  }

  setItem(
    key: string,
    item: string,
    _source: AsyncChangeSources = AsyncChangeSources.user,
  ): Promise<void> {
    return AsyncStorage.setItem(key, item);
  }

  getItem(key: string): Promise<string | null> {
    return AsyncStorage.getItem(key);
  }
}

// -----------------------------------------------------------------------------
export class Memories implements AsyncMemory {
  nvm: AsyncMemory | null = null;

  static inst(): Memories {
    if (memInst === null) {
      memInst = new Memories();
    }
    return memInst;
  }

  constructor() {
    this.addMemory(AsyncChangeSources.nvm, AsyncMemory.inst());
  }

  addMemory(tag: AsyncChangeSources, mem: AsyncMemory) {
    switch (tag) {
      case AsyncChangeSources.nvm:
        this.nvm = mem;
        break;
      default:
        console.warn("AsyncStorage::That memory doesn't exist ", tag);
        break;
    }
  }

  removeMemory(tag: AsyncChangeSources) {
    switch (tag) {
      case AsyncChangeSources.nvm:
        this.nvm = null;
        break;

      default:
        console.warn("AsyncStorage::That memory doesn't exist ", tag);
        break;
    }
  }

  setItem(
    key: string,
    item: string,
    source: AsyncChangeSources = AsyncChangeSources.user,
  ): Promise<void> {
    const p = new Promise<void>(async (resolve, _reject) => {
      const mems: (null | AsyncMemory)[] = [this.nvm];
      mems.forEach((mem: null | AsyncMemory) => {
        if (mem) {
          mem.setItem(key, item, source);
        }
      });
      resolve();
    });
    return p;
  }

  recurseAndRemoveParents(obj: any) {
    const r: any = {};

    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        // console.log('attr:' + attr);
        if (attr !== 'parent') {
          const child: any = obj[attr];
          // console.log('typeof child:' + typeof child);
          if (typeof child === 'object') {
            r[attr] = this.recurseAndRemoveParents(child);
          } else {
            r[attr] = obj[attr];
          }
        }
      }
    }
    return r;
  }

  setItemRecurse(
    key: string,
    obj: any,
    source: AsyncChangeSources = AsyncChangeSources.user,
  ): Promise<void> {
    const p = new Promise<void>(async (resolve, _reject) => {
      try {
        // We need to remove the parents before calling json.stringify.
        // const r = this.recurseAndRemoveParents(obj);
        this.setItem(key, obj, source);
      } catch (e) {
        dbg.err('AsyncStorage::Caught exception in setItemRecurse.');
      }
      resolve();
    });
    return p;
  }

  getItem(key: string): Promise<string | null> {
    if (this.nvm) {
      return this.nvm.getItem(key);
    } else {
      return new Promise<string | null>((resolve, _reject) => {
        resolve(null);
      });
    }
  }
}

// -----------------------------------------------------------------------------
export function setItemInMemories(
  key: string,
  item: string,
  source: AsyncChangeSources = AsyncChangeSources.user,
): Promise<void> {
  return Memories.inst().setItem(key, item, source);
}

// -----------------------------------------------------------------------------
export function setItemInMemoriesRecurse(
  key: string,
  item: any,
  source: AsyncChangeSources = AsyncChangeSources.user,
): Promise<void> {
  return Memories.inst().setItemRecurse(key, item, source);
}

// -----------------------------------------------------------------------------
export function getItemInMemories(key: string): Promise<string | null> {
  return Memories.inst().getItem(key);
}

// -----------------------------------------------------------------------------
function doSomethingStateUpdated(aAny: any) {
  const a: ReduxActions = aAny;
  switch (a.type) {
    case ActionNames.SetCurrentTemperamentAction:
      {
        const a2: SetCurrentTemperamentAction = aAny;
        dbg.log('async::SetCurrentTemperamentAction()  saving...' + a2);
        if (a2.temperamentName && a2.temperamentName.length > 0) {
          // From tuning, navigate upwards to find parent and grandparent.
          setItemInMemories(
            TingsToStore.Temperament,
            a2.temperamentName,
          ).catch();
        } else {
          setItemInMemories(TingsToStore.Temperament, '').catch();
          dbg.log('Saving no temperament.');
        }
      }
      break;

    case ActionNames.SetReferenceFreqAction: {
      const a2: SetReferenceFreqAction = aAny;
      dbg.log('async::SetReferenceFreqAction()  saving...' + a2.referenceFreq);
      if (a2.referenceFreq) {
        setItemInMemories(
          TingsToStore.ReferenceFrequency,
          a2.referenceFreq.toFixed(3),
        ).catch();
      }
      break;
    }

    case ActionNames.ChooseAudioDeviceByDeviceIdAction: {
      const a2: ChooseAudioDeviceByDeviceIdAction = aAny;
      dbg.log(
        'async::ChooseAudioDeviceByDeviceIdAction() saving...' + a2.deviceId,
      );
      if (a2.deviceId && a2.deviceId.length) {
        AsyncStorage.setItem(
          TingsToStore.AudioInputDevice,
          a2.deviceId,
        ).catch();
      }
      break;
    }

    case ActionNames.StartedAudioAction: {
      const a2: StartedAudioAction = aAny;
      dbg.log('async::StartedAudioAction() saving...' + a2.deviceId);
      if (a2.deviceId && a2.deviceId.length) {
        AsyncStorage.setItem(
          TingsToStore.AudioInputDevice,
          a2.deviceId,
        ).catch();
      }
      break;
    }

    case ActionNames.SetTranspositionAction: {
      const a2: SetTranspositionAction = aAny;
      if (a2.transposition >= 0) {
        const t = Math.floor(a2.transposition) % 12;
        setItemInMemories(TingsToStore.Transposition, t.toString()).catch();
      }
      break;
    }

    case ActionNames.SetTuningAction: {
      let savedToNvm = false;
      const a2: SetTuningAction = aAny;
      if (a2.tuning && a2.tuning.name && a2.tuning.name.length > 0) {
        dbg.log(
          'async::SetTuningAction() saving: ' +
            a2.tuning.name +
            ' ' +
            a2.tuning.parent?.name +
            ' ' +
            a2.tuning.parent?.parent?.name,
        );

        const parent = a2.tuning.parent;
        const grandparent = parent?.parent;
        if (parent && grandparent) {
          savedToNvm = true;
          // From tuning, navigate upwards to find parent and grandparent.
          setItemInMemories(TingsToStore.Instrument, grandparent.name).catch();
          setItemInMemories(TingsToStore.SubInstrument, parent.name).catch();
          setItemInMemories(TingsToStore.Tuning, a2.tuning.name).catch();
        }
      } else {
        dbg.log('async::SetTuningAction() saving: no tuning.');
      }
      if (!savedToNvm) {
        setItemInMemories(TingsToStore.Instrument, '').catch();
        setItemInMemories(TingsToStore.SubInstrument, '').catch();
        setItemInMemories(TingsToStore.Tuning, '').catch();
      }

      return 'Saved items after tunings chosen';
    }

    case ActionNames.SetShowAmplitudeAction: {
      const a2: SetShowAmplitudeAction = aAny;
      setItemInMemories(
        TingsToStore.ShowAmplitude,
        a2.showAmplitude.toString(),
      ).catch();
      return 'Saved set amplitude';
    }

    case ActionNames.SetAdvancedGraphicsAction: {
      const a2: SetAdvancedGraphicsAction = aAny;
      setItemInMemories(
        TingsToStore.AdvancedGraphics,
        a2.advancedGraphicsOn.toString(),
      ).catch();
      return 'Saved advanced graphics';
    }

    case ActionNames.SetStrobeDisplayTypeAction: {
      const a2: SetStrobeDisplayTypeAction = aAny;
      setItemInMemories(
        TingsToStore.StrobeDisplayType,
        a2.strobeDisplayType.toString(),
      ).catch();
      return 'Saved strobe display type';
    }

    case ActionNames.SetSensitivityDbAction: {
      const a2: SetSensitivityDbAction = aAny;
      setItemInMemories(
        TingsToStore.Sensitivity,
        a2.sensitivitydB.toString(),
      ).catch();
      return 'Saved sensitivity';
    }

    case ActionNames.SetFftSizeAction: {
      const a2: SetFftSizeAction = aAny;
      setItemInMemories(TingsToStore.FftSize, a2.fftSize.toString()).catch();
      return 'Saved fft size';
    }

    case ActionNames.SetIsProVersionAction: {
      const a2: SetIsProVersionAction = aAny;
      setItemInMemories(
        TingsToStore.IsProVersion,
        a2.isProVersion.toString(),
      ).catch();
      return 'Saved pro version';
    }

    default:
      break;
  }

  return;
}

// -----------------------------------------------------------------------------
// Initialization -----
function loadSettingsFromStorage(_a: ReduxActions): string {
  AsyncStorage.getItem(TingsToStore.AudioInputDevice).then(
    (deviceId: null | string) => {
      if (deviceId) {
        Store.inst().store.dispatch(Dispatch.chooseAudioInputDevice(deviceId));
      }
    },
  );
  AsyncStorage.getItem(TingsToStore.ShowAmplitude).then(
    (showAmplitude: null | string) => {
      if (showAmplitude !== null) {
        const b = showAmplitude === true.toString() ? true : false;
        Store.inst().store.dispatch(Dispatch.setShowAmplitudeAction(b));
      }
    },
  );
  AsyncStorage.getItem(TingsToStore.AdvancedGraphics).then(
    (advancedGraphicsOn: null | string) => {
      if (advancedGraphicsOn !== null) {
        const b = advancedGraphicsOn === true.toString() ? true : false;
        Store.inst().store.dispatch(Dispatch.setAdvancedGraphicsAction(b));
      } else {
        // Default to not advanced graphics on android.
        // @ts-ignore 2367
        if (PlatformType === 'android') {
          Store.inst().store.dispatch(
            Dispatch.setAdvancedGraphicsAction(false),
          );
        }
      }
    },
  );
  AsyncStorage.getItem(TingsToStore.StrobeDisplayType).then(
    (strobeDisplayType: null | string) => {
      if (strobeDisplayType !== null) {
        const i = parseInt(strobeDisplayType, 10);
        Store.inst().store.dispatch(Dispatch.setStrobeDisplayType(i));
      }
    },
  );

  AsyncStorage.getItem(TingsToStore.Sensitivity).then(
    (sensitivitydB: null | string) => {
      if (sensitivitydB !== null) {
        const f = parseFloat(sensitivitydB);
        Store.inst().store.dispatch(Dispatch.setSensitivityDb(f));
      }
    },
  );

  AsyncStorage.getItem(TingsToStore.FftSize).then((fftSize: null | string) => {
    if (fftSize !== null) {
      let i = parseInt(fftSize, 10);
      switch (i) {
        case 4096:
          i = 4096;
          break;
        case 2048:
          i = 2048;
          break;
        default:
          i = 1024;
          break;
      }
      Store.inst().store.dispatch(Dispatch.setFftSize(i));
    }
  });

  AsyncStorage.getItem(TingsToStore.CustomerInfo).then(
    (customerInfo: null | string) => {
      if (customerInfo !== null) {
        const ci = JSON.parse(customerInfo);
        Store.inst().store.dispatch(
          Dispatch.customerInfoChanged(ci, 'asyncStorage'),
        );
      }
    },
  );

  AsyncStorage.getItem(TingsToStore.IsProVersion).then(
    (value: string | null) => {
      if (value !== null) {
        const b = value === true.toString() ? true : false;
        if (b) {
          dbg.log('Setting pro version to ' + b + ' from async storage.');
          Store.inst().store.dispatch(Dispatch.setIsProVersion(b));
        }
      }
    },
  );

  getItemInMemories(TingsToStore.Instrument).then(
    (instrument: null | string) => {
      getItemInMemories(TingsToStore.SubInstrument).then(
        (subInstrument: null | string) => {
          getItemInMemories(TingsToStore.Tuning).then(
            (tuning: null | string) => {
              getItemInMemories(TingsToStore.Temperament).then(
                (_temperament: null | string) => {
                  const temperament = _temperament ? _temperament : '';
                  Store.inst().store.dispatch(
                    Dispatch.setCurrentTemperamentByName(temperament),
                  );
                  if (
                    instrument &&
                    subInstrument &&
                    tuning &&
                    instrument.length > 0 &&
                    subInstrument.length > 0 &&
                    tuning.length > 0
                  ) {
                    dbg.log(
                      'Setting current instrument to ',
                      instrument,
                      subInstrument,
                      tuning,
                    );
                    Store.inst().store.dispatch(
                      Dispatch.setCurrentInstrumentByName(
                        instrument,
                        subInstrument,
                        tuning,
                      ),
                    );
                  }
                  Store.inst().store.dispatch(
                    Dispatch.info(InfoNames.AsyncStorageLoadCompleted),
                  );
                },
              );
            },
          );
        },
      );
    },
  );

  return 'Saved items after tunings chosen';
}

function* onInitialization(actionAny: any, ..._otherArgs: any[]) {
  yield call(loadSettingsFromStorage, actionAny);
}

function* onInitializationEffect() {
  yield takeLatest(InfoNames.DefaultInstrumentsLoadCompleted, onInitialization);
}
// END Initialization -----
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
function* onStateChanged(_actionAny: any) {
  // dbg.log('async::onStateChanged()');
  yield call(doSomethingStateUpdated, _actionAny);
}

function* onReduxStateChangedEffect() {
  dbg.log('async::onStateChangedEffect()');
  yield takeEvery('*', onStateChanged);
}

function* onCustomerInfoChangedEffect() {
  function* onCustomerInfoChanged(a: CustomerInfoChangedInfo) {
    const customerInfo = a.customerInfo;
    if (a.source !== 'asyncStorage') {
      AsyncStorage.setItem(
        TingsToStore.CustomerInfo,
        JSON.stringify(customerInfo),
      );
    }
    yield 0;
  }

  yield takeEvery(InfoNames.CustomerInfoChanged, onCustomerInfoChanged);
}
// -----------------------------------------------------------------------------
// Some complicated code to save changed instruments to NVM and firebase.
// -----------------------------------------------------------------------------

export const AsyncStorageSideEffects = [
  onInitializationEffect,
  onReduxStateChangedEffect,
  onCustomerInfoChangedEffect,
];
