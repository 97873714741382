// This is currently only used for the web app
import {useCallback, useEffect, useState} from 'react';
import {isEqual as lodashIsEqual} from 'lodash';
import {
  StrobeEvt,
  ThreshEvt,
  NoteEvt,
  NoteEvtDefault,
  ThreshEvtDefault,
  CENTS_IN_TUNE,
  AUDIO_IN_DEFAULT,
  PERFECT,
  CHROMATIC,
} from '../headers/note_defs';

import {
  copyInstrument,
  copySubInstrument,
  copyTuning,
  ElementBaseHelper,
  findSubInstrument,
  InstrumentAsMap,
  InstrumentElement,
  SubInstrumentElement,
  TuningElement,
} from '../instruments/instrument_defs';

import {NoteMappings} from '../instruments/note_mapping';

import * as AudioIn from '@abstractions/audio_in';

import {dbg, isDebug, StartStopTimeProfiler} from '../debug/debug';

import {roundFrequency} from '../utils/utils';
import {TweakFreqByCents} from '../headers/midi';
import {StroboProCpp} from '@abstractions/wrapper/js_wrapper';
import {Colors} from '../themes/colors';
import {KalmanFilter} from '@common/utils/kalman';
// import assert from 'assert';

export type StrobeState = {
  position: number;
  relativeVelocityRadians: number;
  color: string;
  relativeAmplitude: number;
  centsError: number;
};

export const strobeStateDefault: StrobeState = {
  position: 0,
  relativeVelocityRadians: 0,
  color: Colors.in_tune,
  relativeAmplitude: 1,
  centsError: 0,
};

const FRAME_VELOCITY_SCALE = Math.PI / 2;
const STROBE_VELOCITY_MAX = 3 * Math.PI;

/**
 * Gets the state of a strobe given its index and strobe event.
 * @param idx - The index of the strobe.
 * @param strobe - The strobe event containing information.
 * @param maxAmplitude - The maximum amplitude value.
 * @returns The state of the strobe.
 */
export function getStrobeState(
  idx: number,
  strobe: StrobeEvt,
  maxAmplitude: number,
): StrobeState {
  // Calculate the frame velocity based on the strobe's velocity and index.
  const frameVelocity = (-strobe.velocity * FRAME_VELOCITY_SCALE) / (idx + 1);

  // Determine the color based on the cents error of the strobe.
  // If the cents error is within the tolerance, the color is green; otherwise, it's orange.
  const color =
    Math.abs(strobe.centsError) < CENTS_IN_TUNE
      ? Colors.in_tune
      : Colors.orange;

  // Create the strobe state object.
  const s: StrobeState = {
    position: strobe.position,
    relativeVelocityRadians: frameVelocity,
    color,
    relativeAmplitude: strobe.amp / maxAmplitude,
    centsError: strobe.centsError,
  };

  return s;
}

function getReferenceFreqWithTranspose(
  mReferenceFreq: number,
  mTransposeFact: number,
) {
  return mReferenceFreq * mTransposeFact;
}

export function getReferenceFreq(transposeSemitones: number, refFreq: number) {
  const transposeFact = Math.pow(2.0, Math.floor(transposeSemitones) / 12.0);
  // final double oldRefToFreq = mFreqToRef;
  const newRefFreq = getReferenceFreqWithTranspose(refFreq, transposeFact);
  // const mFreqToRef = 440.0 / newRefFreq;
  //  const mRefToFreq = 1.0 / mFreqToRef;
  return newRefFreq;
}

export interface ReduxSpecificNote {
  freq: number;
  note: number;
  octave: number;
  tag: number;
}

export enum StrobeDisplayTypes {
  Velocity,
  Position,
}

export interface GlobalState {
  isInit: boolean;
  isProVersion: boolean;
  audioStarted: boolean;
  audioDevicesByDeviceId: Map<string, AudioInputDeviceInfo>;
  currentAudioDeviceDeviceId: string;
  currentAudioDeviceLabel: string;
  minFreq: number; // TODO: Implement reinit when changed.
  maxFreq: number; // TODO: Implement reinit when changed.
  referenceFreq: number;
  transposition: number;
  fftSize: number;
  strobeDisplayType: StrobeDisplayTypes.Velocity | StrobeDisplayTypes.Position;
  sensitivitydB: number;
  allInstrumentsMap: Map<string, InstrumentElement>;
  allInstrumentsUpdatesCount: number;
  allTemperamentsMap: Map<string, TuningElement>;
  currentTemperamentName: string;
  currentInstrumentName: string;
  currentSubInstrumentName: string;
  currentTuningName: string;
  currentTuning?: TuningElement;
  activeNotesList: {
    freqsAry: number[];
    notesAry: number[];
    octavesAry: number[];
    tagsAry: number[];
  };
  lockedNote: null | ReduxSpecificNote;
  alsoLockAnalysis: boolean;
  firebaseUid: string;
  firebaseLoggedIn: boolean;
  firebaseLoadCompleted: boolean;
  showAmplitude: boolean;
  micPermission: boolean;
  advancedGraphicsOn: boolean;
}

export const defaultGlobalState: GlobalState = {
  isInit: false,
  isProVersion: false || isDebug,
  audioStarted: false,
  audioDevicesByDeviceId: new Map(),
  currentAudioDeviceDeviceId: AUDIO_IN_DEFAULT,
  currentAudioDeviceLabel: AUDIO_IN_DEFAULT,
  minFreq: 27 * 1.5,
  maxFreq: 11050,
  referenceFreq: 440,
  transposition: 0,
  fftSize: 2048,
  strobeDisplayType: StrobeDisplayTypes.Velocity,
  sensitivitydB: 9,
  allInstrumentsMap: new Map(),
  allInstrumentsUpdatesCount: 0,
  allTemperamentsMap: new Map(),
  currentTemperamentName: 'None',
  currentInstrumentName: 'Chromatic',
  currentSubInstrumentName: 'None',
  currentTuningName: 'None',
  activeNotesList: {
    freqsAry: [],
    notesAry: [],
    octavesAry: [],
    tagsAry: [],
  },
  lockedNote: null,
  alsoLockAnalysis: false,
  firebaseUid: '',
  firebaseLoggedIn: false,
  firebaseLoadCompleted: false,
  showAmplitude: true,
  micPermission: false,
  advancedGraphicsOn: true,
};

export interface ReduxActions {
  type: string;
}

// tslint:disable-next-line
export interface InitAction extends ReduxActions {}

export interface SetIsProVersionAction extends ReduxActions {
  isProVersion: boolean;
}

export interface SetReferenceFreqAction extends ReduxActions {
  referenceFreq: number;
}

export interface SetFftSizeAction extends ReduxActions {
  fftSize: number;
}

export interface SetStrobeDisplayTypeAction extends ReduxActions {
  strobeDisplayType: StrobeDisplayTypes.Velocity | StrobeDisplayTypes.Position;
}

export interface SetSensitivityDbAction extends ReduxActions {
  sensitivitydB: number;
}

// TODO take this from the actual typescript
export interface AudioInputDeviceInfo {
  label: string;
  deviceId: string;
  groupId: string;
  kind: string;
}

export interface AddAudioInputDeviceAction extends ReduxActions {
  deviceInfo: AudioInputDeviceInfo;
}

export interface ChooseAudioDeviceByDeviceIdAction extends ReduxActions {
  deviceId: string;
}

export interface SetTranspositionAction extends ReduxActions {
  transposition: number;
}

export interface StartAudioAction extends ReduxActions {
  start: boolean;
}

export interface StopAudioAction extends ReduxActions {
  dummy?: boolean;
}

export interface StartedAudioAction extends ReduxActions {
  started: boolean;
  deviceId: string;
}

export interface AddInstrumentAction extends ReduxActions {
  instrument: InstrumentElement;
  isDefaultInstrument: boolean;
  fromFirebase: boolean;
}

export interface DeleteTuningAction extends ReduxActions {
  tuning: TuningElement;
}

// tslint:disable-next-line
export interface SetInstrumentsDirtyAction extends ReduxActions {}

export interface AddTemperamentAction extends ReduxActions {
  temperament: TuningElement;
}

export interface SetCurrentTemperamentAction extends ReduxActions {
  temperamentName: string;
}

export interface SetCurrentInstrumentAction extends ReduxActions {
  instrumentName: string;
  subInstrumentName: string;
  tuningName: string;
}

export interface SetTuningAction extends ReduxActions {
  tuning: null | TuningElement;
}

export interface SetActiveNotesListAction extends ReduxActions {
  freqsAry: number[];
  notesAry: number[];
  octavesAry: number[];
  tagsAry: number[];
}

export interface LockToNoteAction extends ReduxActions {
  lockedNote: null | ReduxSpecificNote;
  alsoLockAnalysis?: boolean;
}
export interface SetFirebaseUidAction extends ReduxActions {
  uid: string;
}

export interface FirebaseLoggedIn extends ReduxActions {
  loggedIn: boolean;
  uid: string;
  user?: any;
}

export interface FirebaseLoadCompleted extends ReduxActions {
  loadCompleted: boolean;
}

export interface SetShowAmplitudeAction extends ReduxActions {
  showAmplitude: boolean;
}

export interface SetAdvancedGraphicsAction extends ReduxActions {
  advancedGraphicsOn: boolean;
}

export interface CustomerInfoChangedInfo extends ReduxActions {
  customerInfo: null | any;
  source: 'purchases' | 'asyncStorage';
}

export const ActionNames = {
  InitAction: 'InitAction',
  SetIsProVersionAction: 'SetIsProVersionAction',
  SetReferenceFreqAction: 'SetReferenceFreqAction',
  SetFftSizeAction: 'SetFftSizeAction',
  SetStrobeDisplayTypeAction: 'SetStrobeTypeAction',
  SetSensitivityDbAction: 'SetSensitivityDbAction',

  AddAudioInputDeviceAction: 'AddAudioInputDeviceAction',
  ChooseAudioDeviceByDeviceIdAction: 'ChooseAudioDeviceByDeviceIdAction',
  SetTranspositionAction: 'SetTranspositionAction',
  StartAudioAction: 'StartAudioAction',
  StopAudioAction: 'StopAudioAction',
  StartedAudioAction: 'StartedAudioAction',
  ResetInstrumentsAction: 'ResetInstrumentsAction',
  AddInstrumentAction: 'AddInstrumentAction',
  DeleteTuningAction: 'DeleteTuningAction',
  SetInstrumentsDirtyAction: 'SetInstrumentsDirtyAction',
  AddTemperamentAction: 'AddTemperamentAction',
  SetCurrentTemperamentAction: 'SetCurrentTemperamentAction',
  SetCurrentInstrumentAction: 'SetCurrentInstrumentAction',
  SetTuningAction: 'SetTuningAction',
  FirebaseLoggedIn: 'FirebaseLoggedIn',
  FirebaseLoadCompleted: 'FirebaseLoadCompleted',

  SetActiveNotesListAction: 'SetActiveNotesListAction',
  LockToNoteAction: 'LockToNoteAction',
  SetShowAmplitudeAction: 'SetShowAmplitudeAction',
  SetAdvancedGraphicsAction: 'SetAdvancedGraphicsAction',
};

export const InfoNames = {
  TemperamentsUpdateCompleted: 'TemperamentsUpdateCompleted',
  DefaultInstrumentsLoadCompleted: 'DefaultInstrumentsLoadCompleted',
  AsyncStorageLoadCompleted: 'AsyncStorageLoadCompleted',

  TuningChosen: 'TuningChosen',
  QueueSaveInstrumentsToNvm: 'QueueSaveInstrumentsToNvm',
  AudioInDevicesEnumerationAttempted: 'AudioInDevicesEnumerationAttempted',
  CustomerInfoChanged: 'CustomerInfoChanged',
};

export const Dispatch = {
  init(): InitAction {
    const rval: InitAction = {
      type: ActionNames.InitAction,
    };
    return rval;
  },

  setIsProVersion(isProVersion: boolean): SetIsProVersionAction {
    const rval: SetIsProVersionAction = {
      type: ActionNames.SetIsProVersionAction,
      isProVersion,
    };
    return rval;
  },

  info(infoString: string): ReduxActions {
    const rval: ReduxActions = {
      type: infoString,
    };
    return rval;
  },

  customerInfoChanged(
    customerInfo: null | any,
    source: 'purchases' | 'asyncStorage',
  ): CustomerInfoChangedInfo {
    // Dispatch, but don't save in redux
    const rval: CustomerInfoChangedInfo = {
      type: InfoNames.CustomerInfoChanged,
      customerInfo,
      source,
    };
    return rval;
  },

  setRefFreq(referenceFreq: number): SetReferenceFreqAction {
    const rval: SetReferenceFreqAction = {
      type: ActionNames.SetReferenceFreqAction,
      referenceFreq,
    };
    return rval;
  },

  setFftSize(fftSize: number): SetFftSizeAction {
    const _fftSize = fftSize === 1024 ? 1024 : fftSize === 4096 ? 4096 : 2048;
    const rval: SetFftSizeAction = {
      type: ActionNames.SetFftSizeAction,
      fftSize: _fftSize,
    };
    return rval;
  },

  setStrobeDisplayType(
    strobeDisplayType: StrobeDisplayTypes,
  ): SetStrobeDisplayTypeAction {
    const rval: SetStrobeDisplayTypeAction = {
      type: ActionNames.SetStrobeDisplayTypeAction,
      strobeDisplayType,
    };
    return rval;
  },

  setSensitivityDb(sensitivitydB: number): SetSensitivityDbAction {
    const rval: SetSensitivityDbAction = {
      type: ActionNames.SetSensitivityDbAction,
      sensitivitydB,
    };
    return rval;
  },

  addAudioInputDevice(
    deviceInfo: AudioInputDeviceInfo,
  ): AddAudioInputDeviceAction {
    const rval: AddAudioInputDeviceAction = {
      type: ActionNames.AddAudioInputDeviceAction,
      deviceInfo,
    };
    return rval;
  },

  // Will only select the device if it exists in the table, and otherwise will ignore it.
  chooseAudioInputDevice(deviceId: string): ChooseAudioDeviceByDeviceIdAction {
    const rval: ChooseAudioDeviceByDeviceIdAction = {
      type: ActionNames.ChooseAudioDeviceByDeviceIdAction,
      deviceId,
    };
    return rval;
  },

  setTransposition(transposition: number): SetTranspositionAction {
    const rval: SetTranspositionAction = {
      type: ActionNames.SetTranspositionAction,
      transposition: Math.floor(transposition) % 12,
    };
    return rval;
  },

  startAudio(start: true): StartAudioAction {
    const rval: StartAudioAction = {
      type: ActionNames.StartAudioAction,
      start,
    };
    return rval;
  },

  startedAudio(started: boolean, deviceId: string): StartedAudioAction {
    const rval: StartedAudioAction = {
      type: ActionNames.StartedAudioAction,
      started,
      deviceId,
    };
    return rval;
  },

  stopAudio(): StopAudioAction {
    const rval: StopAudioAction = {
      type: ActionNames.StopAudioAction,
    };
    return rval;
  },

  resetInstruments(): ReduxActions {
    const rval: ReduxActions = {
      type: ActionNames.ResetInstrumentsAction,
    };
    return rval;
  },

  addInstrument(
    instrument: InstrumentElement,
    isDefaultInstrument: boolean = false,
    fromFirebase: boolean = false,
  ): AddInstrumentAction {
    assert(instrument);
    const rval: AddInstrumentAction = {
      type: ActionNames.AddInstrumentAction,
      instrument,
      isDefaultInstrument,
      fromFirebase,
    };
    return rval;
  },

  deleteTuning(tuning: TuningElement): DeleteTuningAction {
    const rval: DeleteTuningAction = {
      type: ActionNames.DeleteTuningAction,
      tuning,
    };
    return rval;
  },

  setInstrumentsDirty(): SetInstrumentsDirtyAction {
    const rval: SetInstrumentsDirtyAction = {
      type: ActionNames.SetInstrumentsDirtyAction,
    };
    return rval;
  },

  addTemperament(temperament: TuningElement): AddTemperamentAction {
    const rval: AddTemperamentAction = {
      type: ActionNames.AddTemperamentAction,
      temperament,
    };
    return rval;
  },

  setCurrentTemperamentByName(
    temperamentName: string,
  ): SetCurrentTemperamentAction {
    const rval: SetCurrentTemperamentAction = {
      type: ActionNames.SetCurrentTemperamentAction,
      temperamentName,
    };
    return rval;
  },

  setCurrentInstrumentByName(
    instrumentName: string,
    subInstrumentName: string,
    tuningName: string,
  ): SetCurrentInstrumentAction {
    const rval: SetCurrentInstrumentAction = {
      type: ActionNames.SetCurrentInstrumentAction,
      instrumentName,
      subInstrumentName,
      tuningName,
    };
    return rval;
  },

  setTuning(tuning: null | TuningElement): SetTuningAction {
    const rval: SetTuningAction = {
      type: ActionNames.SetTuningAction,
      tuning,
    };
    return rval;
  },

  setActiveNotesList(
    freqs: number[] = [],
    octaves: number[] = [],
    notes: number[] = [],
    tags: number[] = [],
  ): SetActiveNotesListAction {
    const rval: SetActiveNotesListAction = {
      type: ActionNames.SetActiveNotesListAction,
      freqsAry: freqs,
      octavesAry: octaves,
      notesAry: notes,
      tagsAry: tags,
    };
    return rval;
  },

  lockToNoteAction(
    note: null | ReduxSpecificNote,
    alsoLockAnalysis: boolean = false,
  ): LockToNoteAction {
    const rval: LockToNoteAction = {
      type: ActionNames.LockToNoteAction,
      lockedNote: note,
      alsoLockAnalysis,
    };
    return rval;
  },

  setFirebaseLoggedIn(
    loggedIn: boolean,
    uid: string,
    user?: any,
  ): FirebaseLoggedIn {
    const rval: FirebaseLoggedIn = {
      type: ActionNames.FirebaseLoggedIn,
      loggedIn,
      uid,
      user,
    };
    return rval;
  },

  setFirebaseLoadCompleted(): FirebaseLoadCompleted {
    const rval: FirebaseLoadCompleted = {
      type: ActionNames.FirebaseLoadCompleted,
      loadCompleted: true,
    };
    return rval;
  },

  setShowAmplitudeAction(showAmplitude: boolean) {
    const rval: SetShowAmplitudeAction = {
      type: ActionNames.SetShowAmplitudeAction,
      showAmplitude,
    };
    return rval;
  },

  setAdvancedGraphicsAction(advancedGraphicsOn: boolean) {
    const rval: SetAdvancedGraphicsAction = {
      type: ActionNames.SetAdvancedGraphicsAction,
      advancedGraphicsOn,
    };
    return rval;
  },
};

const initialSystemState: GlobalState = {...defaultGlobalState};

// Helper function that starts audio
const _doStartAudio = (state: GlobalState) => {
  const device = state.audioDevicesByDeviceId.get(
    state.currentAudioDeviceDeviceId,
  );
  let newState: GlobalState = {...state, audioStarted: true};
  let p: Promise<string> | null = null;
  if (device) {
    newState.currentAudioDeviceDeviceId = device.deviceId;
    newState.currentAudioDeviceLabel = device.label;
    p = AudioIn.TunerAudio.inst().startAudio(device.deviceId);
  } else {
    p = AudioIn.TunerAudio.inst().startAudio(AUDIO_IN_DEFAULT);
    newState = {
      ...newState,
      currentAudioDeviceLabel: AUDIO_IN_DEFAULT,
      currentAudioDeviceDeviceId: AUDIO_IN_DEFAULT,
    };
  }

  p.then(() => {
    dbg.log('redux::Started audio using device:', device ? device : 'DEFAULT');
    try {
      if (state.audioDevicesByDeviceId.size === 0) {
        dbg.log(
          'redux::There are no devices listed. Enumerating devices again',
        );
        AudioIn.TunerAudio.enumerateDevices();
      }
    } catch (err) {
      dbg.err('redux::caught error enumerating devices.', err);
    }
  }).catch(error => {
    alert(
      'Could not start audio with device ' +
        device?.label +
        ':' +
        error.toString(),
    );
  });
  return newState;
};

// Change state based on actions.
export function systemReducer(
  state: GlobalState = initialSystemState,
  action: ReduxActions,
): GlobalState {
  const actionAny: any = action;
  let newState: undefined | GlobalState;

  switch (action.type) {
    case ActionNames.InitAction: {
      if (!state.isInit) {
        // Todo: move this somewhere else.
        StroboProCpp.inst()._tunerPromise?.then(() => {
          StroboProCpp.inst().WrapperTraceEn();
          StroboProCpp.inst().WrapperSetCallback(
            (
              timestampMs: number,
              _noteEvt?: NoteEvt,
              _threshEvt?: ThreshEvt,
            ) => {
              onThreshEvt(timestampMs, _noteEvt, _threshEvt);
            },
          );
        });

        NoteMappings.inst().hi();
        newState = {...state, isInit: true};
      }
      break;
    }

    case ActionNames.SetIsProVersionAction: {
      const a: SetIsProVersionAction = actionAny;
      newState = {...state, isProVersion: a.isProVersion};
      break;
    }

    case ActionNames.StartAudioAction: {
      const a: StartAudioAction = actionAny;
      if (!state.audioStarted && a.start) {
        newState = _doStartAudio(state);
      } else {
        dbg.log(
          "Skipping audio start: Already started or you didn't ask for it.",
        );
      }
      break;
    }

    case ActionNames.StopAudioAction: {
      if (state.audioStarted) {
        newState = {...state, audioStarted: false};
        AudioIn.TunerAudio.inst().stopAudio();
      } else {
        dbg.log("Skipping StopAudio since it's already stopped");
      }
      break;
    }

    case ActionNames.SetReferenceFreqAction: {
      const a: SetReferenceFreqAction = actionAny;

      if (a.referenceFreq) {
        if (
          state.referenceFreq > 0 &&
          a.referenceFreq !== state.referenceFreq
        ) {
          newState = {
            ...state,
            referenceFreq: a.referenceFreq,
          };
        }
      }
      break;
    }

    case ActionNames.SetFftSizeAction: {
      const a: SetFftSizeAction = actionAny;

      if (a.fftSize) {
        if (state.fftSize !== a.fftSize) {
          newState = {
            ...state,
            fftSize: a.fftSize,
          };
        }
      }
      break;
    }

    case ActionNames.SetStrobeDisplayTypeAction: {
      const a: SetStrobeDisplayTypeAction = actionAny;
      if (a.strobeDisplayType !== state.strobeDisplayType) {
        newState = {
          ...state,
          strobeDisplayType: a.strobeDisplayType,
        };
      }

      break;
    }

    case ActionNames.SetSensitivityDbAction: {
      const a: SetSensitivityDbAction = actionAny;

      if (a.sensitivitydB) {
        if (state.sensitivitydB !== a.sensitivitydB) {
          newState = {
            ...state,
            sensitivitydB: a.sensitivitydB,
          };
        }
      }
      break;
    }

    case ActionNames.AddAudioInputDeviceAction: {
      const a: AddAudioInputDeviceAction = actionAny;

      if (
        !!a.deviceInfo &&
        !!a.deviceInfo.label &&
        a.deviceInfo.label.length > 0 &&
        !!a.deviceInfo.deviceId &&
        a.deviceInfo.deviceId.length > 0
      ) {
        newState = state;
        newState.audioDevicesByDeviceId.set(
          a.deviceInfo.deviceId,
          a.deviceInfo,
        );
      }

      break;
    }

    case ActionNames.ChooseAudioDeviceByDeviceIdAction: {
      const a: ChooseAudioDeviceByDeviceIdAction = actionAny;

      if (!!a.deviceId && !!a.deviceId && a.deviceId.length > 0) {
        const chosenDevice = state.audioDevicesByDeviceId.get(a.deviceId);
        if (chosenDevice) {
          const device: AudioInputDeviceInfo = chosenDevice;
          // Found it
          newState = state;
          newState.currentAudioDeviceDeviceId = device.deviceId;
          newState.currentAudioDeviceLabel = device.label;
          if (state.audioStarted) {
            newState = _doStartAudio(newState);
          }

          dbg.log('Audio devices:', newState.audioDevicesByDeviceId);
        }
      }

      break;
    }

    case ActionNames.SetTranspositionAction: {
      const a: SetTranspositionAction = actionAny;

      if (a.transposition >= 0) {
        newState = {
          ...state,
          transposition: a.transposition,
        };
      }
      break;
    }

    case ActionNames.AddTemperamentAction: {
      const a: AddTemperamentAction = actionAny;
      newState = {...state};
      newState.allTemperamentsMap.set(a.temperament.name, a.temperament);

      break;
    }

    case ActionNames.ResetInstrumentsAction: {
      newState = {...state};
      newState.currentTuning = undefined;
      newState.currentTuningName = CHROMATIC;
      newState.currentSubInstrumentName = 'None';
      newState.currentTuningName = 'None';
      newState.currentTemperamentName = 'None';

      newState.allInstrumentsMap = new Map();
      newState.allInstrumentsUpdatesCount += 1;
      break;
    }

    case ActionNames.AddInstrumentAction: {
      const a: AddInstrumentAction = actionAny;
      newState = {...state};

      const existingInstrument = newState.allInstrumentsMap.get(
        a.instrument.name,
      );
      newState.allInstrumentsUpdatesCount += 1;

      const newInstrument = copyInstrument(a.instrument, true);
      if (!existingInstrument) {
        newState.allInstrumentsMap.set(a.instrument.name, newInstrument);
      } else {
        newInstrument.subInstrument.forEach(
          (incomingSubInstrument: SubInstrumentElement) => {
            const existingSubInstrument = findSubInstrument(
              existingInstrument,
              incomingSubInstrument.name,
            );
            if (!existingSubInstrument) {
              copySubInstrument(
                incomingSubInstrument,
                existingInstrument,
                true,
              );
              // Nothing to do... just copied it.
            } else {
              // This sub instrument existed, copy over the new tunings.
              incomingSubInstrument.tuning.forEach(
                (incomingTuning: TuningElement) => {
                  copyTuning(incomingTuning, existingSubInstrument, true);
                },
              );
            }
          },
        );
      }

      break;
    }

    case ActionNames.DeleteTuningAction: {
      const a: DeleteTuningAction = actionAny;
      if (!a.tuning || a.tuning.isDefaultTuning) {
        break;
      }
      const e = new ElementBaseHelper(a.tuning);
      const instrument = e.getGrandParent() as InstrumentElement;
      const subinstrument = e.getParent() as SubInstrumentElement;
      if (!!subinstrument && !!instrument) {
        newState = {...state};

        const existingInstrument = newState.allInstrumentsMap.get(
          instrument.name,
        );
        if (existingInstrument) {
          newState.allInstrumentsUpdatesCount += 1;
          const mapOfSubInstruments = new InstrumentAsMap(existingInstrument);
          const existingSubInstrument = mapOfSubInstruments.getChild(
            subinstrument.name,
          );
          if (existingSubInstrument) {
            const s = existingSubInstrument as SubInstrumentElement;
            const existingSubInstrumentMap = new InstrumentAsMap(s);
            const existingTuning = existingSubInstrumentMap.getChild(
              a.tuning.name,
            );
            if (existingTuning) {
              // Delete this tuning.
              existingSubInstrumentMap.deleteChild(a.tuning.name);
            }
          }
          if (existingSubInstrument) {
            const s = existingSubInstrument as SubInstrumentElement;
            if (s.tuning.length === 0) {
              // Delete this subinstrument from the instrument
              mapOfSubInstruments.deleteChild(s.name);
            }
          }
        }
        if (existingInstrument) {
          if (existingInstrument.subInstrument.length === 0) {
            // Delete this instrument from the instruments map
            newState.allInstrumentsMap.delete(existingInstrument.name);
          }
        }
      }

      break;
    }

    case ActionNames.SetInstrumentsDirtyAction: {
      newState = {...state};
      newState.allInstrumentsUpdatesCount += 1;
      break;
    }

    case ActionNames.SetCurrentTemperamentAction: {
      const a: SetCurrentTemperamentAction = actionAny;
      newState = {...state};
      newState.currentTemperamentName =
        a.temperamentName.length > 0 ? a.temperamentName : PERFECT;

      // Note, setting current instrument will result in SetTemperament, so no need to save
      // temperament here.

      break;
    }

    case ActionNames.SetCurrentInstrumentAction: {
      const a: SetCurrentInstrumentAction = actionAny;
      newState = {...state};
      newState.currentInstrumentName =
        a.instrumentName.length > 0 ? a.instrumentName : CHROMATIC;
      newState.currentSubInstrumentName =
        a.subInstrumentName.length > 0 ? a.subInstrumentName : 'None';
      newState.currentTuningName =
        a.tuningName.length > 0 ? a.tuningName : 'None';

      // Note, setting current instrument will result in SetTuning, so no need to save
      // tuning here.
      break;
    }

    case ActionNames.SetTuningAction: {
      const a: SetTuningAction = actionAny;
      newState = {...state};
      dbg.log('redux::SetTuningAction::Got tuning ', a.tuning?.name);
      newState.currentInstrumentName = CHROMATIC;
      newState.currentSubInstrumentName = 'None';
      newState.currentTuningName = 'None';
      if (a.tuning) {
        const parent = a.tuning.parent;
        const grandparent = parent?.parent;
        if (parent && grandparent) {
          // From tuning, navigate upwards to find parent and grandparent.
          newState.currentTuningName =
            a.tuning.name.length > 0 ? a.tuning.name : CHROMATIC;
          newState.currentSubInstrumentName = parent.name;
          newState.currentInstrumentName =
            grandparent.name.length > 0 ? grandparent.name : CHROMATIC;
        }
      } else {
        newState.currentInstrumentName = CHROMATIC;
        newState.currentSubInstrumentName = 'None';
        newState.currentTuningName = 'None';
      }
      newState.currentTuning = a.tuning ? a.tuning : undefined;
      break;
    }

    case ActionNames.FirebaseLoggedIn: {
      const a: FirebaseLoggedIn = actionAny;
      newState = {...state, firebaseLoggedIn: a.loggedIn, firebaseUid: a.uid};
      break;
    }

    case ActionNames.SetActiveNotesListAction: {
      const a: SetActiveNotesListAction = actionAny;
      newState = {...state, activeNotesList: {...a}};
      break;
    }

    case ActionNames.LockToNoteAction: {
      const a: LockToNoteAction = actionAny;
      if (a.lockedNote !== state.lockedNote) {
        newState = {
          ...state,
          lockedNote: a.lockedNote,
          alsoLockAnalysis: a.alsoLockAnalysis ? true : false,
        };
      }
      break;
    }

    case ActionNames.SetShowAmplitudeAction: {
      const a: SetShowAmplitudeAction = actionAny;
      newState = {...state, showAmplitude: a.showAmplitude};
      break;
    }

    case ActionNames.SetAdvancedGraphicsAction: {
      const a: SetAdvancedGraphicsAction = actionAny;
      newState = {...state, advancedGraphicsOn: a.advancedGraphicsOn};
      break;
    }

    case ActionNames.FirebaseLoadCompleted: {
      const a: FirebaseLoadCompleted = actionAny;
      newState = {...state, firebaseLoadCompleted: a.loadCompleted};
      break;
    }

    case InfoNames.AsyncStorageLoadCompleted:
    case InfoNames.DefaultInstrumentsLoadCompleted:
    case InfoNames.TemperamentsUpdateCompleted:
    case InfoNames.TuningChosen:
    case InfoNames.QueueSaveInstrumentsToNvm:
    case InfoNames.AudioInDevicesEnumerationAttempted:
    case InfoNames.CustomerInfoChanged:
      break;

    default: {
      dbg.log('Unknown action:' + actionAny.type);
      break;
    }
  }

  newState = newState ? newState : state;
  // dbg.log('newState.allInstrumentsMap', newState.allInstrumentsMap);
  return newState;
}

// ----------------------------------------------------------------------------
// End blahblah reducers
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Start AUDIO Stuff
// ----------------------------------------------------------------------------

export interface AudioState {
  timestampMs: number;
  noteEvt: NoteEvt;
  threshEvt: ThreshEvt;
  noteString: string;
  octaveString: string;
  strobeLockFreq: number;
  iirLockFreq: number;
  strobeCents: number;
  strobeFreq: number;
  isInTune: boolean;
  textColor: string;
  isAnalyzing: boolean;
  isTrackingANote: boolean;
  strobesStateAry: StrobeState[];
}

export const defaultAudioState: AudioState = {
  timestampMs: 0,
  noteEvt: NoteEvtDefault,
  threshEvt: ThreshEvtDefault,
  noteString: '',
  octaveString: '',
  strobeLockFreq: 0,
  iirLockFreq: 0,
  strobeCents: 0,
  strobeFreq: 0,
  isInTune: false,
  textColor: Colors.idle,
  isAnalyzing: false,
  isTrackingANote: false,
  strobesStateAry: [
    strobeStateDefault,
    strobeStateDefault,
    strobeStateDefault,
    strobeStateDefault,
    strobeStateDefault,
    strobeStateDefault,
    strobeStateDefault,
  ],
};

const initialAudioState: AudioState = {...defaultAudioState};

type AudioHookCallbackType = {
  profiler: StartStopTimeProfiler;
  cb: (timestampMs: number, state: AudioState) => void;
  intervalMs: number;
  nextRunMs: number;
};
class MyCallbacks {
  // Data
  callbacks: AudioHookCallbackType[] = [];

  subscribe(
    callbackFn: (timestampMs: number, state: AudioState) => void,
    dbgName?: string,
    intervalMs?: number,
  ) {
    let functionName = this.callbacks.length.toString() + '. ';
    functionName += dbgName ? dbgName : 'MyHookCallback';
    const intervalMsLocal = intervalMs ? intervalMs : 0;

    const hook: AudioHookCallbackType = {
      profiler: new StartStopTimeProfiler(functionName),
      cb: callbackFn,
      intervalMs: intervalMsLocal,
      nextRunMs: 0,
    };
    this.callbacks.push(hook);
  }

  unsubscribe(cb: (timestampMs: number, state: AudioState) => void) {
    const newCallbacks: AudioHookCallbackType[] = [];
    this.callbacks.forEach((hook: AudioHookCallbackType) => {
      if (cb !== hook.cb) {
        newCallbacks.push(hook);
      }
    });
    this.callbacks = newCallbacks;
  }

  numCallCallbacksCalls: number = 0;

  // Debug version of CallCallbacks, to see which callbacks start to take too much time
  // with enough running time.
  myHookDebugCallCallbacks(timestampMs: number, state: AudioState) {
    let currTime = Date.now();
    this.callbacks.forEach((hook: AudioHookCallbackType) => {
      if (hook.intervalMs > 0) {
        const msOverrun = currTime - hook.nextRunMs;
        if (msOverrun < 0) {
          return;
        }
        const delayMs = Math.max(
          hook.intervalMs - msOverrun,
          hook.intervalMs / 2,
        );
        hook.nextRunMs = currTime + delayMs;
      }
      hook.profiler.start(currTime);
      hook.cb(timestampMs, state);
      currTime = Date.now();
      hook.profiler.ping(currTime);
    });
    this.numCallCallbacksCalls++;
    if (this.numCallCallbacksCalls > 500) {
      this.numCallCallbacksCalls = 0;
      dbg.log('myHookCallCallbacks::numCallbacks', this.callbacks.length);
    }
  }

  myHookCallCallbacks(timestampMs: number, state: AudioState) {
    const seeMyChangesState = state;
    if (!isDebug) {
      this.callbacks.forEach((hook: AudioHookCallbackType) => {
        if (hook.intervalMs > 0) {
          const msOverrun = timestampMs - hook.nextRunMs;
          if (msOverrun < 0) {
            return;
          }
          const delayMs = Math.max(
            hook.intervalMs - msOverrun,
            hook.intervalMs / 2,
          );
          hook.nextRunMs = timestampMs + delayMs;
        }

        hook.cb(timestampMs, seeMyChangesState);
      });
    } else {
      this.myHookDebugCallCallbacks(timestampMs, state);
    }
  }
}

let audioStateHolderInst: null | AudioStateHolder = null;
export class AudioStateHolder {
  callbacks = new MyCallbacks();
  threshDirty = false;

  noteDirty = false;
  static inst(): AudioStateHolder {
    if (audioStateHolderInst === null) {
      audioStateHolderInst = new AudioStateHolder();
    }
    return audioStateHolderInst;
  }

  currentAudioState: AudioState = {...initialAudioState};

  // Called by the audio thread
  applyNoteEvent(timestampMs: number, noteEvt: NoteEvt) {
    // dbg.log('Got noteEvt:' + noteEvt.note + noteEvt.octave.toString());

    const newState = this.currentAudioState;
    newState.timestampMs = timestampMs;
    newState.noteString = noteEvt.note;
    newState.octaveString = noteEvt.octave.toFixed(0);
    newState.noteEvt = noteEvt;
    newState.iirLockFreq = noteEvt.iirLockFreq;
    this.currentAudioState = newState;
    this.noteDirty = true;
  }

  kalmanFilterC: KalmanFilter = new KalmanFilter(0.0001, 0.003);
  kalmanFilterF: KalmanFilter = new KalmanFilter(0.0001, 0.003);
  lastNote: string = '';
  lastOctave: number = 0;
  wasAnalyzing: boolean = false;
  strobesActiveMs: number = 0;
  strobesCurrLockFreq: number = 0;

  _getMaxStrobe(threshEvt: ThreshEvt, newState: AudioState): StrobeEvt {
    let maxAmplitude = 0;
    let _maxIdx = 0;

    const nStrobes = Math.min(AudioIn.NUM_STROBES, threshEvt.strobesActive);

    // Get maximum amplitude and strobeCents from the most active strobe
    for (let i = 0; i < nStrobes; i++) {
      if (maxAmplitude < threshEvt.strobeAry[i].amp) {
        maxAmplitude = threshEvt.strobeAry[i].amp;
        _maxIdx = i;
      }
    }
    maxAmplitude = maxAmplitude > 0 ? maxAmplitude : 1;
    const maxStrobe = threshEvt.strobeAry[_maxIdx];

    // Calculate strobe state and include maximum amplitude
    for (let i = 0; i < nStrobes; i++) {
      newState.strobesStateAry[i] = getStrobeState(
        i,
        threshEvt.strobeAry[i],
        maxAmplitude,
      );
    }
    return maxStrobe;
  }

  _resetStrobes(newState: AudioState) {
    newState.textColor = Colors.idle;
    newState.isAnalyzing = false;
    for (let i = 0; i < AudioIn.NUM_STROBES; i++) {
      const c = newState.strobesStateAry[i].color;
      Object.assign(newState.strobesStateAry[i], strobeStateDefault);
      newState.strobesStateAry[i].color = c;
    }
  }

  applyThreshEvent(timestampMs: number, threshEvt: ThreshEvt) {
    const newState = this.currentAudioState;
    newState.timestampMs = timestampMs;

    newState.threshEvt = threshEvt;
    if (!threshEvt.analysisActive) {
      this._resetStrobes(newState);
    } else {
      if (
        !this.wasAnalyzing ||
        this.strobesCurrLockFreq !== newState.strobeLockFreq
      ) {
        this.strobesActiveMs = timestampMs;
        this.strobesCurrLockFreq = newState.strobeLockFreq;
        this._resetStrobes(newState);
        this.kalmanFilterC.reset(0);
        this.kalmanFilterF.reset(this.strobesCurrLockFreq);
        newState.textColor = Colors.in_tune;
        newState.isInTune = true;
      }
      newState.isAnalyzing = true;
      const strobesActiveTime = timestampMs - this.strobesActiveMs;

      if (strobesActiveTime >= 66) {
        const maxStrobe = this._getMaxStrobe(threshEvt, newState);

        const absNoteCentsErr = Math.abs(newState.noteEvt.centsError);
        newState.strobeLockFreq = threshEvt.strobeAry[0].lockFreq;
        let useKalman =
          this.lastNote === newState.noteEvt.note &&
          this.lastOctave === newState.noteEvt.octave;
        if (newState.iirLockFreq >= 1) {
          // Don't do any averaging.
          useKalman = false;
          newState.strobeCents = maxStrobe.centsError;
          newState.strobeFreq = TweakFreqByCents(
            newState.strobeLockFreq,
            newState.strobeCents,
          );
        } else if (absNoteCentsErr > 40) {
          // Too much error: use latest note event.
          newState.strobeCents = useKalman
            ? this.kalmanFilterC.update(newState.noteEvt.centsError)
            : newState.noteEvt.centsError;
          newState.strobeFreq = useKalman
            ? this.kalmanFilterF.update(newState.noteEvt.frequency)
            : newState.noteEvt.frequency;
        } else {
          // Use strobe event
          newState.strobeCents = useKalman
            ? this.kalmanFilterC.update(maxStrobe.centsError)
            : maxStrobe.centsError;
          newState.strobeFreq = TweakFreqByCents(
            newState.strobeLockFreq,
            newState.strobeCents,
          );
        }
        if (!useKalman) {
          this.kalmanFilterC.reset(newState.strobeCents);
          this.kalmanFilterF.reset(newState.strobeFreq);
        }

        const absStrobeCentsErr = Math.abs(newState.strobeCents);
        const isInTune = absStrobeCentsErr < CENTS_IN_TUNE;
        newState.textColor = isInTune ? Colors.in_tune : Colors.orange;
        newState.isInTune = isInTune;

        if (absStrobeCentsErr > 30) {
          const velocity =
            newState.strobeCents > 0
              ? -STROBE_VELOCITY_MAX
              : STROBE_VELOCITY_MAX;
          for (let i = 0; i < AudioIn.NUM_STROBES; i++) {
            const strobe = newState.strobesStateAry[i];
            strobe.relativeVelocityRadians = velocity;
          }
        }
      }
    }

    // If not frequency (ie note) is detected,
    newState.isTrackingANote =
      newState.noteEvt.frequency > 0 && newState.isAnalyzing;

    // And text color should stay while not tracking
    newState.textColor = newState.isTrackingANote
      ? newState.textColor
      : Colors.idle;

    this.wasAnalyzing = newState.isAnalyzing;
    this.lastNote = newState.noteEvt.note;
    this.lastOctave = newState.noteEvt.octave;

    this.currentAudioState = newState;
    this.threshDirty = true;
  }

  callCallbacks() {
    const dirty = this.threshDirty || this.noteDirty;
    if (!dirty) {
      return;
    }
    const rval = {...this.currentAudioState};

    // If thresh is dirty but note is not dirty, then set isAnalyzing to false
    if (this.threshDirty) {
      if (!this.noteDirty) {
        this.currentAudioState.isAnalyzing = false; // Note is not dirty, so not analyzing.
      }

      this.threshDirty = false;
    }

    // If note is dirty and we haven't called callbacks yet, then call them.
    if (this.noteDirty) {
      this.noteDirty = false;
    }

    this.callbacks.myHookCallCallbacks(
      this.currentAudioState.timestampMs,
      rval,
    );
  }
}

export function useAudioState(
  dbgName?: string,
  intervalMs?: number,
): AudioState {
  const [audioState, setAudioState] = useState<AudioState>(defaultAudioState);
  const audioStateCb = useCallback(
    (_timestampMs: number, state: AudioState) => {
      setAudioState(prev => {
        if (_timestampMs !== prev.timestampMs) {
          return {...state};
        }
        return prev;
      });
    },
    [],
  );

  useEffect(() => {
    const intervalMsLocal = intervalMs ? intervalMs : 0;
    AudioStateHolder.inst().callbacks.subscribe(
      audioStateCb,
      dbgName,
      intervalMsLocal,
    );
    return () => {
      AudioStateHolder.inst().callbacks.unsubscribe(audioStateCb);
    };
  }, [audioStateCb, dbgName, intervalMs]);

  return audioState;
}

export function useNoteEvents(
  dbgName?: string,
  intervalMs?: number,
): AudioState {
  const [audioState, setAudioState] = useState<AudioState>(defaultAudioState);

  const audioStateCb = useCallback(
    (_timestampMs: number, state: AudioState) => {
      setAudioState(prev => {
        if (_timestampMs !== prev.timestampMs) {
          if (!lodashIsEqual(state.noteEvt, prev.noteEvt)) {
            return {...state};
          }
        }
        prev.timestampMs = state.timestampMs;
        return prev;
      });
    },
    [],
  );

  useEffect(() => {
    const intervalMsLocal = intervalMs ? intervalMs : 0;
    AudioStateHolder.inst().callbacks.subscribe(
      audioStateCb,
      dbgName,
      intervalMsLocal,
    );
    return () => {
      AudioStateHolder.inst().callbacks.unsubscribe(audioStateCb);
    };
  }, [audioStateCb, dbgName, intervalMs]);

  return audioState;
}

export function useThreshEvents(
  dbgName?: string,
  intervalMs?: number,
): AudioState {
  const [audioState, setAudioState] = useState<AudioState>(defaultAudioState);

  const audioStateCb = useCallback(
    (_timestampMs: number, state: AudioState) => {
      setAudioState(prev => {
        if (_timestampMs !== prev.timestampMs) {
          if (!lodashIsEqual(state.threshEvt, prev.threshEvt)) {
            return {...state, strobesStateAry: [...state.strobesStateAry]};
          }
        }
        prev.timestampMs = state.timestampMs;
        return prev;
      });
    },
    [],
  );

  useEffect(() => {
    const intervalMsLocal = intervalMs ? intervalMs : 0;

    AudioStateHolder.inst().callbacks.subscribe(
      audioStateCb,
      dbgName,
      intervalMsLocal,
    );
    return () => {
      AudioStateHolder.inst().callbacks.unsubscribe(audioStateCb);
    };
  }, [audioStateCb, dbgName, intervalMs]);

  return audioState;
}

export const useThreshEventsSlow = useThreshEvents;
export const useNoteEventsSlow = useNoteEvents;

export interface NoteAveragerOut {
  completed: boolean;
  isStarted: boolean;
  isListening: boolean;
  average: number;
}

// Helper function for averaging incoming notes.
export function useNoteAverager(
  _started: boolean,
  onCompleted?: (avg: number) => void,
  minFrequency: number = 27,
  maxFrequency: number = 5200,
  ratio: number = 0.1,
  minErrRatio: number = 0.01,
): NoteAveragerOut {
  const noteEvt = useNoteEvents('NoteAverager');

  const [isStarted, setStarted] = useState(false);
  const [noteCounter, setNoteCounter] = useState(0);
  const [sampledAverage, setSampledAverage] = useState(440);
  const [newFreq, setNewFreq] = useState(0);
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    if (isStarted && !completed) {
      const f = noteEvt.noteEvt.frequency;
      if (f && f >= minFrequency && f <= maxFrequency) {
        setNewFreq(f);
      }
    }
  }, [
    noteEvt.noteEvt.frequency,
    isStarted,
    completed,
    minFrequency,
    maxFrequency,
  ]);

  useEffect(() => {
    if (newFreq) {
      setNewFreq(0);

      if (noteCounter === 0) {
        setSampledAverage(newFreq);
      } else {
        const newAvg = ratio * newFreq + (1.0 - ratio) * sampledAverage;
        const diff = Math.abs(newAvg - newFreq);
        const err = diff / newAvg;
        dbg.log('err', err);
        setSampledAverage(newAvg);
        if (noteCounter > 5 && err < minErrRatio) {
          setCompleted(newAvg);
          if (onCompleted) {
            onCompleted(roundFrequency(newAvg));
          }
        }
      }
      setNoteCounter(nc => nc + 1);
    }
  }, [newFreq, noteCounter, ratio, sampledAverage, minErrRatio, onCompleted]);

  useEffect(() => {
    if (_started !== isStarted) {
      if (_started) {
        setNoteCounter(0);
      } else {
        setCompleted(0);
      }
      setStarted(_started);
    }
  }, [_started, isStarted]);
  const isCompleted = completed !== 0;

  return {
    completed: isCompleted,
    isStarted,
    isListening: isStarted && !isCompleted,
    average: roundFrequency(sampledAverage),
  };
}

// Handles callbacks from the audio thread.
function onThreshEvt(
  timestampMs: number,
  _noteEvt?: NoteEvt,
  _threshEvt?: ThreshEvt,
) {
  if (_noteEvt) {
    AudioStateHolder.inst().applyNoteEvent(timestampMs, _noteEvt);
  }
  if (_threshEvt) {
    AudioStateHolder.inst().applyThreshEvent(timestampMs, _threshEvt);
  }
}

// Call this from the audio thread when new events have been relayed.
export function CommitAudioEvents() {
  AudioStateHolder.inst().callCallbacks();
}

export {AUDIO_IN_DEFAULT, CHROMATIC, PERFECT, CENTS_IN_TUNE};

// It is possible to do a web version and a native version... see quarantine holdem
function alert(arg0: string) {
  dbg.err('Alert function is not implemented. arg0:', arg0);
}

function assert(item: any) {
  if (!item) {
    dbg.err('ERROR: Assertion failed', item);
  }
}
